<template>
  <div>
    <div class="box-card">
      <div style="margin-bottom: 10px"><i class="card-line"></i>周次管理</div>

      <div style="margin: 10px 0;">
        <span style="font-size: 13px;font-weight: 400">周次：</span>
        <i style="font-size: 12px;margin-left: 10px">第</i>
        <el-input-number v-model="num" style="width: 100px;margin: 0 10px" :min="1" clearable size="mini">
        </el-input-number>
        <i style="font-size: 12px;margin-right: 20px">周</i>
        <span style="font-size: 13px;font-weight: 400px">时间区间：</span>
        <div v-loading="!showDate2" element-loading-spinner="el-icon-loading"
          style="width: 350px;display: inline-block">
          <el-date-picker v-model="dateRange" :disabledDate="disabledDate" style="width: 150px" :disabled="showDate1"
            type="date" size="mini" placeholder="开始日期">
          </el-date-picker>
          <span style="margin: 0 10px;color:#4e4747">-</span>
          <el-date-picker v-if="showDate2" v-model="dateRange2" style="width: 150px" :disabledDate="disabledDate"
            type="date" size="mini" :default-value="new Date(yearNew, dayNew - 1, 1)" placeholder="结束日期">
          </el-date-picker>
        </div>
        <!--        <el-date-picker-->
        <!--            size="mini" style="width: 250px"-->
        <!--            type="daterange"-->
        <!--            v-model="dateRange"-->
        <!--            start-placeholder="开始日期"-->
        <!--            end-placeholder="结束日期"-->
        <!--        />-->
        <el-button size="mini" type="primary" style="float: right;" @click="addTime">添加周次</el-button>
      </div>
      <spread-table v-loading="loading" :tableHeight="tableHeight" :operation="operation" :pageSize="pageSize"
        :currentPage="currentPage" :total="total" @sizeChange="sizeChange" @currentChange="currentChange"
        @clickButton="clickButton" :tableColumn="drafrList" :tableData="spreadTable"></spread-table>
    </div>

  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import SpreadTable from '@/components/SpreadTable'
import {
  clickButtons, currentChanges, sizeChanges, getUserLists, addTimes,
  backInfo
} from "./timeMethods";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
  },
  setup() {
    let router = useRouter();
    onMounted(() => {
      getUserList()
    })
    let data = reactive({
      drafrList: [
        { name: '周次', text: 'currentWeek' },
        { name: '开始日期', text: 'startTime' },
        { name: '结束日期', text: 'endTime' },
      ],
      currentPage: 1,
      pageSize: 1000,
      total: 0,
      loading: true,
      operation: ['删除'],//,'成员权限'
      spreadTable: [],
      tableHeight: 400,
      num: 1,
      dateRange: '',
      dateRange2: '',
      date: '',
      showDate: false,
      showDate1:false,
      showDate2: false,
      yearNew: 2021,
      dayNew: 4,
    })
    let getUserList = () => { getUserLists(data) }
    let sizeChange = (val) => { sizeChanges(val, data) }
    let currentChange = (val) => { currentChanges(val, data) }
    let clickButton = (val) => { clickButtons(val, data, router) }

    let backDia = () => { backInfo(data) }
    let addTime = () => { addTimes(data) }
    let disabledDate = (time) => {
      // if (time.getTime() < new Date() - 8.64e6) {
      //   return true
      // }
    }

    return {
      ...toRefs(data),
      getUserList,
      sizeChange,
      currentChange,
      clickButton,
      backDia,
      addTime,
      disabledDate
    }
  }
})
</script>

<style scoped lang="less">
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background:  #3B6EFB;
}
</style>